import React from "react";
import styled from "styled-components";

import Image from "./images/image.component";

export default function ContactInfo({ align }) {
  return (
    <MainWrapper align={align}>
      <InfoWrapper>
        <div className="imageContainer">
          <div className="image">
            <Image src="/assets/icons/phone.svg" width="50px" />
          </div>
        </div>
        <span>06 01 09 64 28</span>
      </InfoWrapper>
      <InfoWrapper>
        <div className="imageContainer">
          <div className="image">
            <Image src="/assets/icons/mail.svg" width="50px" />
          </div>
        </div>
        <span>contact@1terco.com</span>
      </InfoWrapper>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  width: 100%;
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: ${(props) => (props.align ? props.align : "center")};

  & > * {
    margin: 0 20px;
  }
`;

const InfoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-family: "Prompt Light";
  color: #241f47;
  margin-top: 5px;

  & .imageContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 12px;
    border-radius: 50px;
    user-select: none;
  }
  & .image {
    width: 50px;
    height: 50px;
  }
`;
